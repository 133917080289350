<template>
    <button 
        class="button" 
        :class="{'button--flat': flat}" 
        :style="styles"
        @click="()=>{click()}"
    >
        <slot/>
    </button>
</template>

<script>
export default {
    props: {
        click: Function,
        flat: Boolean,
        styles: Object
    }
}
</script>

<style lang="scss">
    .button {
        min-width: 120px;
        height: 40px;
        font-size: 20px;
        line-height: 20px;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: transparent;
        border-radius: 5px;
        transition: .4s ease;
        transition-property: transform, box-shadow;
        color: #000;
        position: relative;
        cursor: pointer;
        z-index: 2;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: linear-gradient(90deg, #AF5B4C 0%, #EE906F 25.31%, #EEC3B3 51.35%, #EE906F 76.87%, #AF5B4C 100%), #C4C4C4;
            border-radius: 5px;
            z-index: -1;
        }

        &:hover,
        &:focus {
            outline: none;
        }

        &--flat {
            color: #fff;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: linear-gradient(90deg, #AF5B4C 0%, #EE906F 25.31%, #EEC3B3 51.35%, #EE906F 76.87%, #AF5B4C 100%), #C4C4C4;
                border-radius: 5px;
                z-index: -1;
            }

            &:after {
                content: '';
                position: absolute;
                top: 1px;
                bottom: 1px;
                left: 1px;
                right: 1px;
                padding: 1px;
                background: #000;
                border-radius: 5px;
                z-index: -1;
            }

        }
    }


</style>