<template>
    <div class="lock-overlay">
        <div class="lock-overlay__content">
            <h1 class="title lock-overlay__title">Lucky календарь<br /> 
                с подарками скоро</h1>
            <div class="lock-overlay__form" v-if="!user">
                <Input 
                    :value="name" 
                    placeholder="Имя"
                    :onChangeHandler="val=>{handleInput(val, 'name')}"/>
                <Input 
                    :value="email" 
                    type="email"
                    placeholder="Электронная почта"
                    :onChangeHandler="val=>{handleInput(val, 'email')}"/>
                <Button :click="clickHandler" :styles="{height: '60px', borderRadius: '10px', fontWeight: '900'}">Оставить заявку</Button>
            </div>
            <div class="lock-overlay__info">
                <p class="lock-overlay__info-text">{{user && user.email ?  `мы скоро сообщим вам на ${user.email} о начале раздачи` : "Оставьте свою почту и мы сообщим вам о начале раздачи"}} </p>
                <p class="lock-overlay__info-accent">Крутых новогодних подарков!</p>
            </div>
        </div>
    </div>
</template>

<script>
    import Input from "./ui/Input"
    import Button from "./ui/Button"

    export default {
        data() {
            return {
                email: "",
                name: ""
            }
        },
        props: {
            user: [Object, Boolean]
        },
        methods: {
            handleInput(value, key) {
                this[key] = value
            },
            clickHandler() {
                this.$emit('registration', {name: this.name, email: this.email})
                this.clearData()
            },
            clearData() {
                this.name = "" 
                this.email = ""
            }
        },
        components: {Input, Button}
    }
</script>

<style lang="scss">
    .lock-overlay {
        width: 100%;
        height: 100%;
        min-height: 730px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 840px)  {
            min-height: 650px;
        }

        &:before {
            content: '';
            position: absolute;
            top: -140px;
            bottom: 0;
            right: auto;
            left: auto;
            width: 100vw;
            background-image: url('../assets/backgroundLock.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            opacity: .2;
            z-index: -1;
        }

        &__content {
            width: 100%;
            max-height: 450px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            @media screen and (max-width: 840px)  {
                padding-left: 15px;
                padding-right: 15px;
                height: auto;
            }
        }

        &__form {
            width: 100%;
            max-width: 1015px;
            margin: 60px 0;
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 840px)  {
                flex-direction: column;
                margin-bottom: 20px;

                input {
                    margin-bottom: 20px;
                }
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media screen and (max-width: 840px)  {
                text-align: center;
                * {
                    text-align: center;
                }
            }

            &-text {
                margin: 0;
            }

            &-accent {
                padding: 7px 10px;
                position: relative;
                color: #AF5B4C;
                background: linear-gradient(90deg, #AF5B4C 0%, #EE906F 25.31%, #EEC3B3 51.35%, #EE906F 76.87%, #AF5B4C 100%), #C4C4C4;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background: linear-gradient(90deg, #AF5B4C 0%, #EE906F 25.31%, #EEC3B3 51.35%, #EE906F 76.87%, #AF5B4C 100%), #C4C4C4;
                    border-radius: 5px;
                    z-index: -1;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 1px;
                    bottom: 1px;
                    left: 1px;
                    right: 1px;
                    padding: 1px;
                    background: #000;
                    border-radius: 5px;
                    z-index: -1;
                }
            }
        }
    }
</style>