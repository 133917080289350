<template>
  <div class="informer" :class="{'informer--error': message && message.type === 'error'}">
      <span>
          {{message && message.text}}
      </span>
  </div>
</template>

<script>
export default {
    props: {
        message: Object,
    }    
}
</script>

<style lang="scss">
    .informer {
        position: fixed;
        top: auto;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 220;
        display: flex;
        align-self: center;
        justify-content: center;
        background-color: #00C618;
        color: #fff;
        padding: 15px;
        opacity: 0.7;
        
        &--error {
            background-color: #FF0D00;
        }
    }
</style>