<template>
  <div id="app">
    <!-- header -->
    <Header :user="user" :isOverlay="Boolean(!isAvaliable)" @registration="auth" @login="login"/>
    <div class="container">
      <!-- page content -->
      <LockOverlay v-if="!isAvaliable" @registration="auth" :user="user ? user : false"/>
      <MainPage v-else :user="user" :today="today"/>
      <Snowf
        :amount="50"
        :size="5"
        :speed="1.5"
        :wind="0"
        :opacity="0.8"
        :swing="1"
        :image="null"
        :zIndex="null"
        :resize="true"
        color="#fff"
      />
      <Informer v-if="showMessage" :message="message"/>
    </div>
  </div>
</template>

<script>

// firebase
import firebase from "firebase/app";
import "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBZplTqySqOg0SNXC4V4OvjTVGcZ8CRmq0",
  authDomain: "lucky-newyear2021.firebaseapp.com",
  projectId: "lucky-newyear2021",
  storageBucket: "lucky-newyear2021.appspot.com",
  messagingSenderId: "738615858488",
  appId: "1:738615858488:web:90b0e0179b14bcf2df9dcd",
  measurementId: "G-J06BCC8XRZ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

import Header from "./components/Header"
import LockOverlay from "./components/LockOverlay"
import MainPage from "./components/MainPage"
import Informer from "./components/mainComponents/Informer"

// snow
import Snowf from 'vue-snowf';
// moment
import dayjs from "dayjs"
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)
import axios from 'axios'

export default {
  name: 'App',
  data() {
    return {
      user: null,
      isAvaliable: false,
      today: dayjs().format('YYYY-MM-DD'),
      message: null,
      showMessage: false
    }
  },
  components: {
    Header,
    LockOverlay,
    MainPage,
    Informer,
    Snowf
  },
  methods: {
    auth ({email, name}) {
      if (email && email.includes('@')) {
        firebase.auth().createUserWithEmailAndPassword(email, '1qazXSW@~555')
          .then((data) =>{
            this.setLocalUser(data.user)
            this.setMessage({
              text: "Вы успешно зарегистрировались",
              type: "success"
            })
          })
          .catch((error) =>{
            console.error(error, error.code)
            if (error.code === "auth/invalid-email") {
              this.setMessage({
                text: "Некорректный email",
                type: "error"
              })
            } else if (error.code === "auth/email-already-in-use") {
              this.setMessage({
                text: "Данный email уже занят",
                type: "error"
              })
            } else {
              this.setMessage({
                text: "Произошла ошибка, попробуйте еще раз",
                type: "error"
              })
            }
          });
      } else {
        this.setMessage({
          text: "Некорректный Email",
          type: "error"
        })
      }
    },
    login({email, name, isDetected}) {
      if (email && email.includes('@')) {
        firebase.auth().signInWithEmailAndPassword(email, '1qazXSW@~555')
          .then((data)=>{
            this.setLocalUser(data.user)
            if (isDetected) {
              this.setMessage({
                text: "С возвращением!",
                type: "success"
              })
            } else {
              this.setMessage({
                text: "Вы успешно залогинились",
                type: "success"
              })
            }            
          })
          .catch((error) =>{
            console.error(error)
            if (error.code === "auth/user-not-found") {
              this.setMessage({
                text: "Такого юзера не существует",
                type: "error"
              })
            } else {
              this.setMessage({
                text: "Произошла ошибка, попробуйте еще раз",
                type: "error"
              })
            }
          });
      } else {
        this.setMessage({
          text: "Некорректный Email",
          type: "error"
        })
      }
    },
    setLocalUser({email, name}) {
      this.user = {email, name}
      window.localStorage.setItem('userEmail', email);
      window.localStorage.setItem('userName', name);
    },
    checkLocalUser() {
      if (localStorage.getItem('userEmail')) {
        const email = localStorage.getItem('userEmail')
        const name = localStorage.getItem('userName') ? localStorage.getItem('userName') : void 0
        this.login({email, name, isDetected: true})
      } 
    },
    checkIsAvailable() {
      if (dayjs(this.today).isSameOrAfter("2010-12-29")) {
        this.isAvaliable = true
      } else {
        this.isAvaliable = false
      }
    },
    setMessage(data) {
      this.showMessage = true
      this.message = data
      this.clearMessage()
    },
    clearMessage() {
      const msgTimer = setTimeout(()=>{
        this.message = null
        this.showMessage = false
        clearTimeout(msgTimer)
      }, 5000)
    }
  },
  mounted() {
    this.checkLocalUser()
    this.checkIsAvailable()
    this.$root.$on('message', this.setMessage)
    this.$root.$on('login', this.login)
  }
}
</script>

<style lang="scss">
@import '~normalize.css';

// fonts
@font-face {
  font-family: "GothamPro-Regular";
  src: url('./assets/fonts/gotham/GothamPro-Regular.woff') format("woff"),
          url('./assets/fonts/gotham/GothamPro-Regular.woff2') format("woff2"),
          url('./assets/fonts/gotham/GothamPro-Regular.eot') format("eot");
}

@font-face {
  font-family: "GothamPro-Bold";
  src: url('./assets/fonts/gotham/GothamPro-Bold.woff') format("woff"),
          url('./assets/fonts/gotham/GothamPro-Bold.woff2') format("woff2"),
          url('./assets/fonts/gotham/GothamPro-Bold.eot') format("eot");
}

@font-face {
  font-family: "GothamPro-Black";
  src: url('./assets/fonts/gotham/GothamPro-Black.woff') format("woff"),
          url('./assets/fonts/gotham/GothamPro-Black.woff2') format("woff2"),
          url('./assets/fonts/gotham/GothamPro-Black.eot') format("eot");
}
@font-face {
  font-family: "GothamPro-Medium";
  src: url('./assets/fonts/gotham/GothamPro-Medium.woff') format("woff"),
          url('./assets/fonts/gotham/GothamPro-Medium.woff2') format("woff2"),
          url('./assets/fonts/gotham/GothamPro-Medium.eot') format("eot");
}

// styles

body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;

  * {
    box-sizing: border-box;
    color: #fff;
    font-family: GothamPro-Regular, sans-serif;
    font-size: 20px;
    line-height: 22px;
  }
}

h1, h2, h3, h4, h5 {
    font-family: GothamPro-Bold, sans-serif;
    font-size: 70px;
    line-height: 91px;
    font-weight: 900;
    text-align: center;

    @media screen and (max-width: 840px)  {
        font-size: 40px;
        line-height: 44px;
    }
}

#app {
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  position: relative;
  padding-top: 140px;
  
  @media screen and (max-width: 840px)  {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px;
  }

  @media (min-width: 841px) and (max-width: 1200px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  .container {
    width: 100%;
    max-width: 1160px;
    height: 100%;
    margin: 0 auto;
  }

  .snowf-canvas {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .title {
    white-space: pre-line;
    word-wrap: break-word; 
    margin: 0;
    margin-bottom: 20px;
    line-height: 80px;
    text-transform: uppercase;
    font-weight: 900;
    color: #AF5B4C;
    background: linear-gradient(90deg, #AF5B4C 0%, #EE906F 25.31%, #EEC3B3 51.35%, #EE906F 76.87%, #AF5B4C 100%), #C4C4C4;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: GothamPro-Black, sans-serif;
    letter-spacing: 5px;

    @media screen and (max-width: 840px)  {
        font-size: 40px;
        line-height: 48px;
    }
  }
}
</style>
