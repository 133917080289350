<template>
    <input 
        class="input"
        :class="{'input--white': white}"
        :placeholder="placeholder"
        :value="value" 
        :style="styles"
        :type="type ? type : 'text'"
        @input="$event=>{
            onChangeHandler($event.target.value)
        }"
    >
</template>

<script>
export default {
    props: {
        value: String,
        onChangeHandler: Function,
        placeholder: String,
        styles: Object,
        type: String,
        white: Boolean || false
    }
}
</script>

<style lang="scss">
    .input {
        display: flex;
        height: 60px;
        background-color: #000;
        border: 1px solid #fff;
        color: #595959;
        border-radius: 10px;
        width: 360px;
        padding: 20px;
        font-size: 18px;
        line-height: 20px;
        font-size: 18px;
        line-height: 20px;
        transition: transform .4s ease;
        box-sizing: border-box;

        &--white {
            background-color: #fff;
            border: 1px solid #AF5B4C;
            color: rgba(0, 0, 0, .4);
        }
        
        &:hover,
        &:focus {
            outline: none;
        }

        // &:focus{
        //     box-shadow: 0px 0px 4px 2px #AF5B4C;
        // }
    }
</style>