export const popupMixin = {
    methods: {
        closePopup() {
            this.$emit('close')
        },
        emitRegistration(user){
            this.$emit('reg', user)
        },
        emitLogin(user) {
            this.$emit('login', user)
        }
    }
}