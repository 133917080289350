<template>
    <div class="main" :class="{'main--z': popupShown}">
        <h1 class="title main__title">Lucky календарь</h1>
        <div class="main__accent">
            <p class="text">
                Друзья, LuckyGroup поздравляет вас с 2021 годом! <span class="text__accent">С 29 декабря по 27 января</span>  каждый день в нашем календаре будет открываться новый подарок. Собирай все бонусы и получай максимальный профит.
            </p>
        </div>
        <div class="main__cards">
            <Card v-for="item in items" :isSelf="item.self" :locked="isLocked(item.date)" :item="item" :open="checkIsOpen(item.id)" :key="item.id" @openCard="openCard" @openLocked="setComponent('Lock')"/>
            <p v-if="!items.length" style="text-align: center">Данные загружаются</p>
        </div>

        <div class="main__share">
            <p class="main__share-title">Поделиться календарем с друзьями</p>
            <YandexShare  :services="['telegram','vkontakte','facebook']"/>
        </div>
        <div class="video-bg" v-if="!isMobile">
            <video autoplay="autoplay" frameborder="0" loop>
                <source src="../../public/video bg_1.webm">
            </video>
        </div>

        <Popup :shown="popupShown" @close="closePopup">
            <component :is="activeComponent" 
            :item="selectedItem" 
            @login="data=>{$root.$emit('login', data); closePopup()}" 
            @close="closePopup" 
            @changeCard="openCard"
            @reg="data=>{regHandler('registration', data)}" 
            @switch="setComponent"
        />        
        </Popup>
    </div>
</template>

<script>
import axios from 'axios'
import Card from './mainComponents/Card'
import Popup from './ui/Popup'
import YandexShare from '@cookieseater/vue-yandex-share'

// day formatter
import dayjs from "dayjs"
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)

export default {
    data() {
        return {
            selectedItem: null,
            items: [],
            popupShown: false,
            activeComponent: 'CardExtended',
            isMobile: false
        }
    },
    mixins: [],
    components: {
        Card, YandexShare, Popup, 
        // popups
        Success: ()=> import('./ui/Success'),
        Lock: ()=> import('./ui/Lock'),
        CardExtended: ()=>import('./ui/CardExtended'),
        Login: ()=> import('./ui/Login'),
        Registration: ()=> import('./ui/Reg')
    }, 
    props: {
        today: String,
        user: Object
    },
    methods: {
        checkIsMobile() {
            if (screen.width < 841) {
                this.isMobile = true
            }            
        },  
        checkIsOpen(id) {
            const opened = localStorage.getItem('openedCards')
            if (opened){
                opened.split(',')
                return opened.includes(String(id))
            }
            return false
            
        },
        isLocked(date) {
            return !dayjs(this.today).isSameOrAfter(dayjs(date, "MM-DD-YYYY"))
        },
        closePopup() {
            this.popupShown = false
            this.activeComponent = null
            this.selectedItem = null
            document.body.style = "overflow-y: auto"
        },
        loadItems() {
            axios.get('/cards.json')            
                .then(data=>{
                    this.items = [...data.data]
                })
                .catch(err=>console.log(err))
        },
        setComponent(value) {
            this.activeComponent = value
            this.popupShown = true
            document.body.style = "overflow-y: hidden"
        },
        openCard(id) {
            if (this.user && this.user.email) {
                let opened = localStorage.getItem('openedCards')
                opened = opened && opened !== null && opened !== 'null' ? opened.split(',') : []
                if (!opened.includes(String(id))) {
                    opened.push(String(id))
                }
                opened = opened.join(',')
                localStorage.setItem('openedCards', opened)
                this.selectedItem = this.items.find(el=>el.id===id)
                this.setComponent('CardExtended')
            } else {
                this.setComponent("Login")
            }
        }
    },
    mounted() {
        this.loadItems()
        this.checkIsMobile()
    }
}
</script>

<style lang="scss">
    .main {
        width: 100%;
        margin-top: 100px;
        padding-bottom: 100px;
        position: relative;
        z-index: 1;

        &--z {
            z-index: 3;
        }

        &__title {
            position: relative;
            z-index: 2;
        }

        .video-bg {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            // z-index: -1;
            opacity: 0.4;
            
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background: radial-gradient(circle, rgba(0,0,0,0.4) 0%, rgba(0,0,0,1) 80%);
            }

            video {
                display: block;
                min-width: 100%; 
                min-height: 100%; 
                width: auto;
                height: auto;
            }
        }

        .text {
            width: 100%;
            padding: 25px 54px;
            position: relative;
            font-size: 19px;
            line-height: 130%;
            text-align: center;
            color: #fff;
            margin-bottom: 60px;
            z-index: 3;


            @media screen and (max-width: 840px) {
                padding: 15px;
                font-size: 16px;
                line-height: 20px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: -1;
                background: linear-gradient(90deg, #AF5B4C 0%, #EE906F 25.31%, #EEC3B3 51.35%, #EE906F 76.87%, #AF5B4C 100%), #C4C4C4;
                border-radius: 10px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 2px;
                bottom: 2px;
                right: 2px;
                left: 2px;
                z-index: -1;
                background: #000;
                border-radius: 10px;
            }

            &__accent {
                font-size: 19px;
                line-height: 130%;
                color: #AF5B4C;
                background: linear-gradient(90deg, #AF5B4C 0%, #EE906F 25.31%, #EEC3B3 51.35%, #EE906F 76.87%, #AF5B4C 100%), #C4C4C4;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }   
        
        &__cards {
            width: calc(100% + 30px);
            display: flex;
            flex-wrap: wrap;

            @media screen and (max-width: 933px) {
                margin: 0;
                padding: 0px 2px;
                width: 100%;
                justify-content: space-between;
            }

            @media (min-width: 934x) and (max-width: 1200px) {
                margin: 0;
                padding: 0px 2px;
                width: calc(100% + 20px);
                justify-content: space-between;
            }
            // justify-content: center;
        }   

        &__share{ 
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 60px;
            position: relative;
            z-index: 3;

            &-title {
                text-align: center;
            }

            .ya-share2__list {
                display: flex;
                justify-content: space-between;
                height: 46px;
                width: 170px;

                .ya-share2__icon {
                    width: 46px!important;
                    height: 46px!important;
                    border-radius: 50%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 46px 46px;
                }

                .ya-share2__badge {
                    background-color: transparent;
                }

                .ya-share2__item_service_vkontakte .ya-share2__icon {
                    background-image: url('../assets/vk.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 46px 46px;
                }

                .ya-share2__item_service_facebook .ya-share2__icon {
                    background-image: url('../assets/fb.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 46px 46px;
                }

                .ya-share2__item_service_telegram .ya-share2__icon {
                    background-image: url('../assets/tg.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 46px 46px;
                }
            }  
        }
    }
</style>