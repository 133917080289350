<template>
  <div class="card" :class="{'card--locked': locked, 'card--open': open, 'card--self': isSelf}" @click="openCard">
        <div class="card__overlay">
            <i class="card__overlay-icon">
            </i>
        </div>
        <i class="card__icon" :style="{'backgroundImage': `url(${item.icon})`}"></i>
        <p class="card__date">{{formatDate}}</p>
        <div class="flash" :style="{'animationDelay': `${item.id * Math.random()*10}s`}"></div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export default {
    props: {
        open: Boolean,
        locked: Boolean,
        item: Object,
        isSelf: Boolean
    },
    computed: {
        formatDate() {
            const date = dayjs(this.item.date, "MM-DD-YYYY").format("DD.MM")
            return date.split('.').map((el, idx)=>idx === 1 ? (el === "12" ? "декабря": "января") : el).join(" ")
        }
    },
    methods: {
        openCard() {
            if (this.locked) {
                this.$emit('openLocked')
            } else {
                this.$emit('openCard', this.item.id)
            }
        },
    }
}
</script>

<style lang="scss">
.card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    margin-right: 28px;
    width: 210px;
    height: 220px;
    position: relative;
    z-index: 3;
    border-radius: 10px;
    padding-top: 30px;
    cursor: pointer;
    overflow: hidden;

    .flash {
        animation: moveAnim 10s ease-in-out infinite;
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 3em;
        display: block;
        position: absolute;
        top: 0;
        left: -4.5em;
        transform: skewX(-40deg) translateX(-100%);
        transition: .4s transform ease-in;
    }

    @media screen and (max-width: 414px) {
        width: 100%;
        height: 250px;
        margin-bottom: 20px;
        margin-right: 0;
    }

    @media (min-width: 415px) and (max-width: 549px) {
        width: 48%;
        height: 190px;
        margin-bottom: 20px;
        margin-right: 0;
    }

    @media (min-width: 550px) and (max-width: 933px) {
        width: 48%;
        height: 250px;
        margin-right: 0;
    }

    @media (min-width: 934px) and (max-width: 1200px) {
        width: 31%;
        margin-right: 21px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        border-radius: 10px;
        background: linear-gradient(90deg, #AF5B4C 0%, #EE906F 25.31%, #EEC3B3 51.35%, #EE906F 76.87%, #AF5B4C 100%), #C4C4C4;
        border-radius: 10px;
    }
    
    .card__date {
        color: #fff!important;
    }

    &__date {
        font-family: Gotham Pro, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;
        color: #74433A;
    }

    i {
        display: block;
        width: auto;
        height: 110px;
    }

    &__icon {
        width: 120px!important;
        height: 110px!important;
        background-size: auto 90px;
        background-repeat: no-repeat;
        background-position: center;
    }

    &--self {

        .flash {
            display: none;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: -1;
            border-radius: 10px;
            background: linear-gradient(147.52deg, #1BCE9B 0.86%, #448AFF 28.02%, #FFFFFF 52.81%, #FFC400 79.14%, #FF6D00 100%);        
        }
        
        &:after {
            content: '';
            position: absolute;
            top: 2px;
            bottom: 2px;
            right: 2px;
            left: 2px;
            z-index: -1;
            background: #000;
            border-radius: 10px;
        }
    }

    // open
    &--open{
        
        .card__overlay {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 10;
            opacity: .7;
            background: #000;
        }
        
        .card__date {
            color: #fff!important;
        }
    }

    &__overlay {
        position: absolute;
        display: none;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 4;
        border-radius: 10px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: #000;
            border-radius: 10px;
            opacity: 0.8;
            z-index: -1;
        }
    }

    &--locked {
        .card__overlay {
            display: flex;   
            align-items: center;
            justify-content: center;

            &-icon {
                width: 90px;
                height: 90px;
                background-image: url('../../assets/images/Vector.svg');
                background-size: auto 90px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}

@keyframes moveAnim {
    0% {
        transform: skewX(-40deg) translateX(-120%);
    }
    20% {
        transform: skewX(-40deg) translateX(2000px);
    }

    100% {
        transform: skewX(-40deg) translateX(2000px);
    }
}
</style>