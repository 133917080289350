export const popupHandler = {
    data() {
        return {
            popupShown: false,
            activeComponent: 'CardExtended'
        }
    },
    methods: {
        setComponent(value) {
            this.activeComponent = value
            this.popupShown = true
            document.body.style = "overflow-y: hidden"
        },
        closePopup() {
            this.popupShown = false
            this.activeComponent = null
            document.body.style = "overflow-y: auto"
        },
        regHandler(type, data) {
            this.$emit(type, data)
            this.closePopup()
        }
    }
}