<template>
    <div class="header">
        <div class="header__inner">
            <div class="header__logo">
                <svg width="250" height="37" viewBox="0 0 250 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M164.092 29.703C164.092 29.703 164.134 29.703 164.092 29.703C165.842 29.703 167.549 29.3616 169.085 28.7214C170.621 28.0813 171.987 27.2277 173.139 26.2462L173.31 26.1181V16.8146H163.324V19.3325H170.749V24.9659C169.939 25.606 169 26.1608 167.89 26.5449C166.695 27.0143 165.457 27.2277 164.177 27.2277C161.403 27.2277 159.227 26.3742 157.605 24.5818C155.983 22.832 155.172 20.4848 155.172 17.7108C155.172 15.0649 155.983 12.803 157.648 10.9679C159.312 9.13281 161.318 8.19392 163.836 8.19392C166.439 8.19392 168.701 9.04745 170.621 10.7118L171.005 11.0533L172.755 9.04745L172.371 8.70604C171.133 7.72448 169.853 6.9563 168.573 6.44418C167.25 5.93206 165.714 5.67599 163.964 5.67599C163.964 5.67599 163.964 5.67599 163.921 5.67599C160.592 5.67599 157.776 6.87094 155.642 9.26084C153.508 11.6081 152.441 14.4674 152.441 17.6681C152.441 21.0396 153.508 23.8989 155.599 26.2035C157.733 28.508 160.592 29.703 164.092 29.703Z" fill="white"/>
                    <path d="M181.162 29.2762V22.1492C181.162 19.7593 181.803 17.8816 183.04 16.5586C184.321 15.1929 185.814 14.5528 187.649 14.5528H188.289V11.8641H187.82C186.113 11.8215 184.619 12.2482 183.254 13.2298C182.443 13.8273 181.717 14.5101 181.12 15.3636V12.1202H178.559V29.2762H181.162Z" fill="white"/>
                    <path d="M199.599 29.6603C202.117 29.6603 204.251 28.7641 205.915 27.0143C207.579 25.2646 208.433 23.1308 208.433 20.6982C208.433 18.2656 207.579 16.1318 205.915 14.382C204.251 12.6323 202.159 11.7361 199.641 11.7361C197.124 11.7361 195.032 12.6323 193.325 14.382C191.661 16.1318 190.807 18.2656 190.807 20.6982C190.807 23.1308 191.661 25.2646 193.325 27.0143C194.99 28.7641 197.081 29.6603 199.599 29.6603ZM199.599 14.1687C201.306 14.1687 202.757 14.7661 203.952 16.0464C205.189 17.3267 205.787 18.8204 205.787 20.6555C205.787 22.4479 205.189 23.9843 203.994 25.2646C202.8 26.5449 201.391 27.185 199.727 27.185C197.977 27.185 196.569 26.5876 195.374 25.3073C194.179 24.027 193.581 22.5333 193.581 20.6982C193.581 18.8631 194.179 17.3694 195.374 16.0891C196.483 14.8088 197.892 14.1687 199.599 14.1687Z" fill="white"/>
                    <path d="M214.408 27.6118C215.645 28.9348 217.352 29.6176 219.486 29.6176C221.705 29.6176 223.498 28.8494 224.821 27.2704V29.1908H227.381V12.0348H224.821V21.8931C224.821 23.4295 224.351 24.6671 223.37 25.6914C222.388 26.6729 221.193 27.185 219.7 27.185C218.206 27.185 217.096 26.7583 216.285 25.8621C215.475 24.9659 215.048 23.7282 215.048 22.1492V12.0348H212.487V22.4479C212.53 24.5818 213.17 26.2888 214.408 27.6118Z" fill="white"/>
                    <path d="M235.49 34.3121V26.7156C237.154 28.6361 239.203 29.5749 241.635 29.5749C243.855 29.5749 245.818 28.7214 247.482 27.057C249.146 25.3926 250 23.2161 250 20.5702C250 17.9242 249.146 15.705 247.482 14.0833C245.818 12.4616 243.855 11.6081 241.635 11.6081C239.245 11.6081 237.154 12.6323 235.49 14.5954V11.9922H232.929V34.2267L235.49 34.3121ZM235.405 20.6982C235.405 18.7777 236.002 17.2414 237.24 16.0038C238.477 14.7661 239.886 14.1687 241.507 14.1687C243.129 14.1687 244.495 14.7661 245.647 15.9611C246.799 17.156 247.354 18.7351 247.354 20.6555C247.354 22.6613 246.799 24.2404 245.69 25.3926C244.58 26.5449 243.214 27.1424 241.507 27.1424C239.886 27.1424 238.477 26.5449 237.24 25.3073C236.002 24.0697 235.405 22.576 235.405 20.6982Z" fill="white"/>
                    <path d="M51.9802 26.8009V6.10275H49.3342L49.3769 29.3189H65.0392V26.8009H51.9802Z" fill="white"/>
                    <path d="M81.2564 12.1202V21.9785C81.2564 23.5149 80.7869 24.7525 79.8054 25.7767C78.8238 26.7583 77.6289 27.2704 76.1352 27.2704C74.6842 27.2704 73.5746 26.8436 72.721 25.9474C71.9102 25.0512 71.4834 23.8136 71.4834 22.2346V12.1202H68.9228V22.5333C68.9228 24.6244 69.563 26.3315 70.8006 27.6972C72.0382 29.0201 73.7453 29.703 75.8791 29.703C78.0983 29.703 79.8907 28.9348 81.2137 27.3558V29.3189H83.7743V12.1629L81.2564 12.1202Z" fill="white"/>
                    <path d="M102.083 24.5818C100.546 26.2888 98.8819 27.185 97.0894 27.185C95.3397 27.185 93.9314 26.5876 92.7364 25.3073C91.5415 24.027 90.944 22.5333 90.944 20.6982C90.944 18.9058 91.5415 17.3694 92.7364 16.0891C93.9314 14.8088 95.297 14.1686 97.0041 14.1686C98.7111 14.1686 100.418 15.0222 102.04 16.6866L102.381 17.0707L104.131 15.2782L103.832 14.9368C101.955 12.8457 99.6927 11.7787 97.0468 11.7787C94.6142 11.7787 92.523 12.675 90.816 14.4247C89.1516 16.1744 88.298 18.3083 88.298 20.7408C88.298 23.2161 89.1516 25.3499 90.816 27.0997C92.4804 28.8494 94.5715 29.703 97.0468 29.703C99.6927 29.703 102.04 28.5507 104.003 26.3315L104.344 25.9474L102.467 24.283L102.083 24.5818Z" fill="white"/>
                    <path d="M123.421 12.0775H119.964L110.661 21.7224V5.12119H108.1V29.2762H110.661V25.0512L114.075 21.5944L120.263 29.2335H123.506L115.825 19.7593L123.421 12.0775Z" fill="white"/>
                    <path d="M132.511 31.2393C131.999 31.7088 131.401 31.9648 130.676 31.9648C129.737 31.9648 128.884 31.7941 128.115 31.41L127.646 31.154L126.75 33.4585L127.176 33.6719C128.286 34.1413 129.438 34.3974 130.548 34.3974C131.828 34.3974 132.98 34.0133 133.919 33.2025C134.816 32.4343 135.669 31.1967 136.395 29.4896L143.692 12.0348H140.918L135.413 25.7767L129.012 12.0775H126.11L134.133 28.6787C133.578 29.8737 133.023 30.7272 132.511 31.2393Z" fill="white"/>
                    <path d="M16.3025 18.1376L9.38887 11.224C8.91943 10.7545 8.27928 10.4985 7.63913 10.4985C6.99898 10.4985 6.35883 10.7545 5.88938 11.224L0.725504 16.3879C0.25606 16.8573 0 17.4974 0 18.1376C0 18.8204 0.25606 19.4179 0.725504 19.8873L5.88938 25.0512C6.35883 25.5207 6.99898 25.7767 7.63913 25.7767C8.32195 25.7767 8.91943 25.5207 9.38887 25.0512L16.3025 18.1376Z" fill="white"/>
                    <path d="M35.5497 19.8873C36.0191 19.4179 36.2752 18.7777 36.2752 18.1376C36.2752 17.4548 36.0191 16.8573 35.5497 16.3878L30.4285 11.224C29.4469 10.2424 27.8679 10.2424 26.929 11.224L20.0154 18.1376L26.8863 25.0512C27.8679 26.0328 29.4469 26.0328 30.3858 25.0512L35.5497 19.8873Z" fill="white"/>
                    <path d="M25.0512 30.3858C26.0328 29.4042 26.0328 27.8252 25.0512 26.8863L18.1376 20.0154L11.224 26.8863C10.7545 27.3557 10.4985 27.9959 10.4985 28.636C10.4985 29.3189 10.7545 29.9163 11.224 30.3858L16.3879 35.5497C16.8573 36.0191 17.4974 36.2752 18.1376 36.2752C18.8204 36.2752 19.4179 36.0191 19.8873 35.5497L25.0512 30.3858Z" fill="white"/>
                    <path d="M10.4985 7.63913C10.4985 8.32195 10.7545 8.91943 11.224 9.38887L18.1376 16.3025L25.0512 9.38887C25.5207 8.91943 25.7767 8.27928 25.7767 7.63913C25.7767 6.9563 25.5207 6.35883 25.0512 5.88938L19.8873 0.725504C19.4179 0.25606 18.7778 0 18.1376 0C17.4974 0 16.8573 0.25606 16.3879 0.725504L11.224 5.88938C10.7545 6.35883 10.4985 6.9563 10.4985 7.63913Z" fill="white"/>
                </svg>

            </div>
            <!-- if not allowed -->
            <div class="header__controls" v-if="isOverlay">
                <!-- <Button flat>Стать партнером акции</Button> -->
            </div>
            <div class="header__controls" :class="{'mob-shown': menuShown}" v-else>
                <template v-if="!user">
                    <Button :styles="{marginRight: '10px'}" :click="()=>{setComponent('Registration'); menuHandler(false)}">Зарегистрироваться</Button>
                    <Button flat :click="()=>{setComponent('Login'); menuHandler(false)}">Войти</Button>
                </template>
                <template v-else>
                    {{user.email ? user.email : null}}
                </template>
            </div>
            <button class="menu-toggler" :class="{'menu-toggler--open':menuShown}" @click="()=>menuHandler()"></button>
        </div>
        <Popup :shown="popupShown" @close="closePopup">
            <component :is="activeComponent" 
                @close="closePopup" 
                @reg="data=>{regHandler('registration', data)}" 
                @login="data=>{regHandler('login', data)}"
                @switch="setComponent"
            />
        </Popup>
    </div>
</template>

<script>
import Button from "./ui/Button"
import Popup from './ui/Popup'
import {popupHandler} from "../mixins/popupHandler"

export default {
    data(){
        return {
            menuShown: false
        }
    },
    mixins: [popupHandler],
    components: {
        Button, Popup,
        Registration: ()=> import('./ui/Reg'),
        Login: ()=> import('./ui/Login'),
    },
    props: {
        user: Object,
        isOverlay: Boolean
    },
    methods: {
        menuHandler(value) {
            if (screen.width <841) {
                this.menuShown = value ? value : !this.menuShown
            }
        }
    }
}
</script>

<style lang="scss" >
    .header {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 140px;
        padding: 50px 0;
        z-index: 2;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 840px)  {
            padding: 20px 15px;
            height: auto;
        }

        @media (min-width: 841px) and (max-width: 1200px) {
            padding-left: 25px;
            padding-right: 25px;
        }

        &__inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 1160px;
            height: 100%;
            position: relative;
            z-index: 2;
        }

        &__logo {
            width: 250px;
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 840px)  {
                width: 170px;
                height: 30px;
            }
        }

        &__controls {
            display: flex;
            align-items: center;

            @media screen and (max-width: 840px) {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transform: translateX(-100%);
                transition: .4s transform ease;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                z-index: 150;

                button {
                    margin-bottom: 20px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: .8;
                    background: #000;
                    z-index: -1;
                }
            }
        }
    }

    .menu-toggler {
        display: none;
    }

    @media screen and (max-width: 840px)  {
        .mob-shown {
            transform: translateX(0);
        }

        .menu-toggler {
            position: absolute;
            top: 20px;
            right: 15px;
            left: auto;
            bottom: auto;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            color: #fff;
            background-color: transparent;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyMiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjQwMDEgMC45MDAwMjRDMS4xODc5MiAwLjkwMDAyNCAwLjk4NDQ0MSAwLjk4NDMxIDAuODM0NDEyIDEuMTM0MzRDMC42ODQzODMgMS4yODQzNyAwLjYwMDA5OCAxLjQ4Nzg1IDAuNjAwMDk4IDEuNzAwMDJDMC42MDAwOTggMS45MTIyIDAuNjg0MzgzIDIuMTE1NjggMC44MzQ0MTIgMi4yNjU3MUMwLjk4NDQ0MSAyLjQxNTc0IDEuMTg3OTIgMi41MDAwMiAxLjQwMDEgMi41MDAwMkgyMC42MDAxQzIwLjgxMjMgMi41MDAwMiAyMS4wMTU4IDIuNDE1NzQgMjEuMTY1OCAyLjI2NTcxQzIxLjMxNTggMi4xMTU2OCAyMS40MDAxIDEuOTEyMiAyMS40MDAxIDEuNzAwMDJDMjEuNDAwMSAxLjQ4Nzg1IDIxLjMxNTggMS4yODQzNyAyMS4xNjU4IDEuMTM0MzRDMjEuMDE1OCAwLjk4NDMxIDIwLjgxMjMgMC45MDAwMjQgMjAuNjAwMSAwLjkwMDAyNEgxLjQwMDFaTTAuNjAwMDk4IDguMTAwMDJDMC42MDAwOTggNy44ODc4NSAwLjY4NDM4MyA3LjY4NDM3IDAuODM0NDEyIDcuNTM0MzRDMC45ODQ0NDEgNy4zODQzMSAxLjE4NzkyIDcuMzAwMDIgMS40MDAxIDcuMzAwMDJIMjAuNjAwMUMyMC44MTIzIDcuMzAwMDIgMjEuMDE1OCA3LjM4NDMxIDIxLjE2NTggNy41MzQzNEMyMS4zMTU4IDcuNjg0MzcgMjEuNDAwMSA3Ljg4Nzg1IDIxLjQwMDEgOC4xMDAwMkMyMS40MDAxIDguMzEyMiAyMS4zMTU4IDguNTE1NjggMjEuMTY1OCA4LjY2NTcxQzIxLjAxNTggOC44MTU3NCAyMC44MTIzIDguOTAwMDIgMjAuNjAwMSA4LjkwMDAySDEuNDAwMUMxLjE4NzkyIDguOTAwMDIgMC45ODQ0NDEgOC44MTU3NCAwLjgzNDQxMiA4LjY2NTcxQzAuNjg0MzgzIDguNTE1NjggMC42MDAwOTggOC4zMTIyIDAuNjAwMDk4IDguMTAwMDJaTTAuNjAwMDk4IDE0LjVDMC42MDAwOTggMTQuMjg3OSAwLjY4NDM4MyAxNC4wODQ0IDAuODM0NDEyIDEzLjkzNDNDMC45ODQ0NDEgMTMuNzg0MyAxLjE4NzkyIDEzLjcgMS40MDAxIDEzLjdIMjAuNjAwMUMyMC44MTIzIDEzLjcgMjEuMDE1OCAxMy43ODQzIDIxLjE2NTggMTMuOTM0M0MyMS4zMTU4IDE0LjA4NDQgMjEuNDAwMSAxNC4yODc5IDIxLjQwMDEgMTQuNUMyMS40MDAxIDE0LjcxMjIgMjEuMzE1OCAxNC45MTU3IDIxLjE2NTggMTUuMDY1N0MyMS4wMTU4IDE1LjIxNTcgMjAuODEyMyAxNS4zIDIwLjYwMDEgMTUuM0gxLjQwMDFDMS4xODc5MiAxNS4zIDAuOTg0NDQxIDE1LjIxNTcgMC44MzQ0MTIgMTUuMDY1N0MwLjY4NDM4MyAxNC45MTU3IDAuNjAwMDk4IDE0LjcxMjIgMC42MDAwOTggMTQuNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;

            &--open {
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjE3NTYgMTEuOTgxOUwxOS44MDc2IDUuMzY3NjFDMTkuOTM4NyA1LjIxNDk3IDIwLjAwNzIgNS4wMTg2NCAxOS45OTk0IDQuODE3ODRDMTkuOTkxNiA0LjYxNzA0IDE5LjkwODIgNC40MjY1NSAxOS43NjU3IDQuMjg0NDZDMTkuNjIzMiA0LjE0MjM3IDE5LjQzMjIgNC4wNTkxMiAxOS4yMzA5IDQuMDUxMzdDMTkuMDI5NSA0LjA0MzYxIDE4LjgzMjcgNC4xMTE5MSAxOC42Nzk2IDQuMjQyNjJMMTIuMDQ3NiAxMC44NTY5TDUuNDE1NjMgNC4yMzQ2NEM1LjI2NDk5IDQuMDg0NCA1LjA2MDY4IDQgNC44NDc2MyA0QzQuNjM0NTkgNCA0LjQzMDI4IDQuMDg0NCA0LjI3OTYzIDQuMjM0NjRDNC4xMjg5OSA0LjM4NDg4IDQuMDQ0MzYgNC41ODg2NSA0LjA0NDM2IDQuODAxMTJDNC4wNDQzNiA1LjAxMzYgNC4xMjg5OSA1LjIxNzM3IDQuMjc5NjMgNS4zNjc2MUwxMC45MTk2IDExLjk4MTlMNC4yNzk2MyAxOC41OTYxQzQuMTk1ODkgMTguNjY3NyA0LjEyNzg3IDE4Ljc1NTcgNC4wNzk4NSAxOC44NTQ3QzQuMDMxODQgMTguOTUzNiA0LjAwNDg1IDE5LjA2MTUgNC4wMDA2IDE5LjE3MTNDMy45OTYzNCAxOS4yODEyIDQuMDE0OSAxOS4zOTA4IDQuMDU1MTIgMTkuNDkzMkM0LjA5NTM0IDE5LjU5NTYgNC4xNTYzNSAxOS42ODg2IDQuMjM0MzEgMTkuNzY2M0M0LjMxMjI4IDE5Ljg0NDEgNC40MDU1MiAxOS45MDQ5IDQuNTA4MTggMTkuOTQ1QzQuNjEwODQgMTkuOTg1MSA0LjcyMDcgMjAuMDAzNiA0LjgzMDg4IDE5Ljk5OTRDNC45NDEwNSAxOS45OTUyIDUuMDQ5MTYgMTkuOTY4MiA1LjE0ODQxIDE5LjkyMDRDNS4yNDc2NiAxOS44NzI1IDUuMzM1OTIgMTkuODA0NiA1LjQwNzYzIDE5LjcyMTFMMTIuMDQ3NiAxMy4xMDY5TDE4LjY3OTYgMTkuNzIxMUMxOC44MzI3IDE5Ljg1MTggMTkuMDI5NSAxOS45MjAxIDE5LjIzMDkgMTkuOTEyNEMxOS40MzIyIDE5LjkwNDYgMTkuNjIzMiAxOS44MjE0IDE5Ljc2NTcgMTkuNjc5M0MxOS45MDgyIDE5LjUzNzIgMTkuOTkxNiAxOS4zNDY3IDE5Ljk5OTQgMTkuMTQ1OUMyMC4wMDcyIDE4Ljk0NTEgMTkuOTM4NyAxOC43NDg4IDE5LjgwNzYgMTguNTk2MUwxMy4xNzU2IDExLjk4MTlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
                z-index: 201;
            }
        }
    }
</style>