<template>
    <div v-if="shown" class="popup popup__wrapper" @click.self="clickHandler($event)">
        <PerfectScrollbar @click.self="clickHandler($event)">
            <div class="popup__item">
                <slot/>
            </div>
        </PerfectScrollbar>   
    </div>
</template>

<script>
import {popupMixin} from "../../mixins/popup"
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
    props: {
        shown: Boolean
    },
    components: {
        PerfectScrollbar
    },
    mixins: [popupMixin],
    methods: {
        clickHandler(event){
            this.closePopup()
        } 
    }
}
</script>

<style lang="scss">
    @import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
    .popup {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 98;

        .ps {
            padding-top: 25vh;
            padding-bottom: 100px;
            width: 100%;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            z-index: 99;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: #000;
            opacity: 0.8;
            z-index: -1;
        }

        &__item {
            width: calc(100% - 40px);
            margin: 0px auto;
            max-width: 660px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 30px;
            margin-bottom: 100px;
            overflow: hidden;
            z-index: 99;
            position: relative;

            * {
                color: #000;
            }

            .popup-item {
                display: flex;
                flex-direction: column;
                align-items: center;

                &__title {
                    font-size: 30px;
                    line-height: 32px;
                    text-align: center;
                    margin-top: 10px;
                    margin-bottom: 30px;
                }

                &__subtitle {
                    text-align: center;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                    margin-top: 0;
                    margin-bottom: 30px;
                }

                &__form {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    @media screen and (max-width: 840px) {
                        flex-direction: column;

                        input {
                            width: 100%!important;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        
    }
</style>